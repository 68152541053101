import { NumberInput, Select, TextInput, Textarea } from "@mantine/core";
import { ENUM_FORM_DISPLAY } from "../../components/pageList";
import { currencyParser, currencyFormat } from "../../hooks/useFomatter";
import AutoCodeInput from "../../components/autoCodeInput";
import {
  DATA_ITEM_TRANSACTION_TYPE,
  DATA_UNIT_OF_MEASURE,
} from "../../data/options";
import remoteSelect2 from "../../components/remoteSelect2";

import { DateInput, DatePicker, DatePickerInput } from "@mantine/dates";

export const ItemTransactionFormSchema = {
  title: "Item Transaction",
  isMainForm: true,
  display: {
    mode: ENUM_FORM_DISPLAY.DRAWER,
    size: "xl", //xs, sm, xl  ....100%
    position: "right",
  },
  apiEntity: "itemTransaction",
  closeAfterSave: true,
  initialValues: {
    code: "",
    name: "",
    cname: "",
  },

  layout: {
    fields: [
      //   {
      //     name: "code",
      //     component: AutoCodeInput,
      //     props: {
      //       required: true,
      //       label: "Code",
      //       placeholder: "",
      //     },
      //   },

      {
        name: "item",
        component: remoteSelect2,
        props: {
          label: "Item",
          apiEntity: "item",
          required: true,
          valueField: "_id",
          labelField: "name",
          searchFields: ["name", "code"],
          pageSize: 1000,
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "transactionDate",
        component: DateInput,
        type: "datePicker",

        props: {
          required: true,
          label: "Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
          type: "datePicker",
        },
      },

      {
        name: "quantity",
        component: NumberInput,
        props: {
          required: true,
          label: "Quantity",
          placeholder: "",
        },
      },
      {
        name: "cost",
        component: NumberInput,
        props: {
          label: "Total Cost",
          placeholder: "",
          precision: 2,
          parser: currencyParser,
          formatter: currencyFormat,
        },
      },
      {
        name: "transactionType",
        component: Select,
        props: {
          required: true,
          label: "Type",
          placeholder: "",
          data: DATA_ITEM_TRANSACTION_TYPE,
        },
      },

      {
        name: "remark",
        component: Textarea,
        props: {
          // required: true,
          label: "Remark",
          placeholder: "",
          mb: "xl",
        },
      },
    ],
  },
};
