export const DashboardViews = [
  {
    id: "DM",
    name: "Management View",
    description: "Show management dashboard page",
  },
  {
    id: "DS",
    name: "Staff View",
    description: "Show staff dashboard page",
  },
];
