import React, { useState, useCallback, useEffect } from "react";
import { useServerApi } from "../hooks/useServerApi";
import { Group, Switch, Text } from "@mantine/core";
import _ from "lodash";
import { DashboardViews } from "../data/dashboardViews";
import JsonViewer from "./common/jsonViewer";
//Dashboard View List
const DashboardViewList = ({ form, name, ...props }) => {
  const [selectedBoards, setSelectedBoards] = useState([]);
  const userRole = form.values;

  useEffect(() => {
    if (!userRole.dashboards) return;
    const selected = DashboardViews.filter((v) =>
      userRole.dashboards.includes(v.id)
    );
    setSelectedBoards(selected);
  }, [userRole]);

  const changedSelected = (id, action) => {
    let newDashboards = [];
    if (action === "add") {
      userRole.dashboards.push(id);
      newDashboards = [...userRole.dashboards];
    } else {
      newDashboards = userRole.dashboards.filter((d) => d != id);
    }
    //Set Form values
    _.set(form.values, name, newDashboards);

    //Update UI
    const selected = DashboardViews.filter((v) => newDashboards.includes(v.id));
    setSelectedBoards(selected);
    return;
  };

  const setViewChecked = (checked, id) => {
    if (!userRole.dashboards || !_.isArray(userRole.dashboards)) return;
    if (!userRole.dashboards.includes(id) && checked) {
      return changedSelected(id, "add");
    }
    if (userRole.dashboards.includes(id) && !checked) {
      return changedSelected(id, "remove");
    }
  };

  return (
    <>
      {!_.isEmpty(DashboardViews) &&
        DashboardViews.map((view) => (
          <Group justify="space-between" style={{ width: "100%" }}>
            <div style={{ marginBottom: 20 }}>
              <Group>
                <Text fontWeight={800} size="sm">
                  {view.name}
                </Text>
              </Group>
              <Text size={"xs"} color="dimmed">
                {`${view.description}`}
              </Text>
            </div>
            <Switch
              checked={selectedBoards.find((b) => b.id === view.id)}
              onChange={(event) =>
                setViewChecked(event.currentTarget.checked, view.id)
              }
            ></Switch>
          </Group>
        ))}
    </>
  );
};

export default DashboardViewList;
