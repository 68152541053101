import React from "react";
import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  Container,
  Group,
} from "@mantine/core";
import { IconExclamationMark, IconCircleCheck } from "@tabler/icons-react";

import { useForm } from "@mantine/form";
import { useServerApi } from "../hooks/useServerApi";
import { showNotification } from "@mantine/notifications";

import { useSignIn, useIsAuthenticated } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const title = "Reset Your Password";
  const [api] = useServerApi();
  const signIn = useSignIn();
  const navigate = useNavigate();
  const isAuthenicated = useIsAuthenticated();

  if (isAuthenicated()) {
    // console.log("Is authenticate");
    navigate("/");
  }

  const form = useForm({
    initialValues: {
      email: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const handleError = ({ message }) => {
    let msg = "";
    switch (message) {
      case "ERR_NO_USER":
        msg = "No this user";
        break;
      case "ERR_USER_INACTIVE":
        msg = "The user is inactive";
        break;
      case "ERR_INVALID_PASSWORD":
        msg = "Invalid password";
        break;
      default:
        msg = message;
        break;
    }

    return showNotification({
      title: "Login Fail",
      color: "red",
      icon: <IconExclamationMark size={18} />,
      message: msg,
    });
  };

  const handleReset = async ({ email }) => {
    // console.log(values);
    try {
      const result = await api.User.resetPassword(email);
      // console.log(result);
      navigate("/login");
      return showNotification({
        title: "Password Reset",
        color: "green",
        icon: <IconCircleCheck size={18} />,
        message: "New password has been sent to your email.",
      });
      return;
    } catch (error) {
      console.log(error);
      return showNotification({
        title: "Login Fail",
        color: "red",
        icon: <IconExclamationMark size={18} />,
        message: error.msg,
      });
    }
  };

  return (
    <Container size={420} my={40}>
      <form onSubmit={form.onSubmit((values) => handleReset(values))}>
        <Title
          align="center"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 900,
          })}
        >
          {title}
        </Title>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <Title order={6} mb="xl">
            Please fill in your official email and click the reset button to
            receive a reset password email.
          </Title>

          <TextInput
            label="Email"
            placeholder="your@email.com"
            required
            {...form.getInputProps("email")}
          />

          <Button fullWidth mt="xl" type="submit">
            Reset Password
          </Button>
        </Paper>
      </form>
    </Container>
  );
};

export default ForgetPassword;
