import { Menu, Group, ActionIcon, Text, Button } from "@mantine/core";
import {
  IconDots,
  IconSettings,
  IconMessageCircle,
  IconPhoto,
  IconSearch,
  IconArrowsLeftRight,
  IconTrash,
  IconPencil,
} from "@tabler/icons-react";

const renderActionButtons = (actions, data, onActionBtnClick, rowIndex) => {
  return actions.map((action, index) => (
    <action.icon
      style={{ cursor: "pointer" }}
      onClick={() => onActionBtnClick({ action, data, rowIndex })}
      size={action.iconSize ?? 16}
      stroke={action.stroke ?? 1.5}
      {...action.props}
    />
  ));
};

const renderActionMenu = (actionMenu, data, onActionBtnClick, rowIndex) => {
  if (!actionMenu || actionMenu.length == 0) return;
  return (
    <Menu transition="pop" withArrow position="bottom-end">
      <Menu.Target>
        <IconDots size={16} stroke={1.5} style={{ cursor: "pointer" }} />
      </Menu.Target>
      <Menu.Dropdown py={"xs"}>
        {actionMenu.map((action, index) =>
          action.icon ? (
            <Menu.Item
              key={index}
              icon={<action.icon size={16} stroke={1.5} />}
              {...action.props}
              onClick={() => onActionBtnClick({ action, data, rowIndex })}
            >
              <Text size="xs">{action.label}</Text>
            </Menu.Item>
          ) : (
            <Menu.Item
              key={index}
              {...action.props}
              onClick={() => onActionBtnClick({ action, data, rowIndex })}
            >
              <Text size="xs">{action.label}</Text>
            </Menu.Item>
          )
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

const ActionCellRender = (column, rowIndex, data, onActionBtnClick) => {
  const defaultActionButtons = [
    {
      name: "edit",
      icon: IconPencil,
      props: {
        //   color:"red"
      },
    },
    {
      name: "delete",
      icon: IconTrash,
      props: {
        //   color:"red"
      },
    },
  ];
  const actionButtons = column.actions ?? defaultActionButtons;

  return (
    <Group gap={"sm"}>
      {/* { JSON.stringify(column)} */}
      {renderActionButtons(actionButtons, data, onActionBtnClick, rowIndex)}
      {renderActionMenu(column.actionMenu, data, onActionBtnClick, rowIndex)}
    </Group>
  );
};

export default ActionCellRender;
