import React from "react";
import { useServerApi } from "../hooks/useServerApi";
import _ from "lodash";
import moment from "moment";
import { PageList } from "./pageList";

import { useCellRender } from "../hooks/useCellRender";
import { useNavigate, useLocation } from "react-router-dom";
import { Badge } from "@mantine/core";
import { useAuthUser } from "react-auth-kit";

const UserClientList = ({ form, userId, ...props }) => {
  const _id = _.get(form?.values, "_id") ?? userId;
  const [api] = useServerApi();
  const [cellRender] = useCellRender();
  const location = useLocation();
  const auth = useAuthUser();

  const { userRole } = auth();

  const getEntityLink = () => {
    const { userRole } = auth();
    if (!userRole) return null;
    const roleLink = userRole.entityLinks?.find((l) => l.entity === "client");
    if (!roleLink || !roleLink.link) return null;
    return roleLink.link;
    // return `../${roleLink.link}?id=${i}`;
  };

  const orderFormSchema = {};

  const tableSchema = {
    preQuery: _id ? { "consultants._id": _id } : null,
    columns: [
      // {
      // 	field: "code",
      // 	filter: true,
      // 	cellRender: (col, client) => (
      // 		<a
      // 			href={`/operation/client?id=${client._id}&mode=${"edit"}`}
      // 			target="_blank"
      // 			style={{ textDecoration: "none" }}
      // 		>
      // 			{client.code}
      // 		</a>
      // 	),
      // },
      {
        field: "name",
        sortable: true,
        headerName: "ID",
        cellRender: (col, client) => (
          <a
            href={`${getEntityLink()}?id=${client._id}&mode=${"edit"}`}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            {cellRender.ClientInfo(client)}
          </a>
        ),
      },

      {
        field: "id",
        sortable: true,
      },
      {
        field: "isTransfer",
        sortable: true,
        headerName: "Source",

        cellRender: (col, data) =>
          data && data.isTransfer ? (
            <Badge size="xs" color={"red"} radius={0}>
              Transfer
            </Badge>
          ) : (
            <Badge size="xs" color={"teal"} radius={0}>
              Direct
            </Badge>
          ),
      },
      {
        field: "consultants",
        sortable: true,
        headerName: "Consultants",
        cellRender: (col, data) => cellRender.Users(data.consultants),
      },

      // {
      // 	field: "order",
      // 	headerName: "Orders",
      // 	sortable: true,
      // 	cellRender: (col, data) => data.orders.length,
      // },
      // { field: "phone", sortable: true },

      {
        field: "status",
        sortable: true,
        cellRender: (col, data) => cellRender.Status(data.status),
      },
    ],
    searchableFields: ["code", "name", "cname", "email", "phone", "id"],
  };

  return (
    <>
      {/* <ReactJson src={form.values} style={{ background: "white" }} /> */}
      {/* {userId} */}
      {/* roleLink: {getEntityLink()} */}
      <PageList
        title=""
        apiEntity="client"
        hideSearchBar={false}
        tableSchema={tableSchema}
        formSchema={orderFormSchema}
        hideActionButton={true}
        tablePadding={-10}
        preQueryNotEmpty={true}
      />
    </>
  );
};

export default UserClientList;
