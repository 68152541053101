export const StatementTableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    columns: [
      {
        field: "code",
        headerName: "Claim",
        sortable: true,
      },
    ],
  };
};
