import { IconSettings } from "@tabler/icons-react";
import { Textarea } from "@mantine/core";

import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";

import { TextInput } from "@mantine/core";
import APIRouteList from "../../components/APIRouteList";
import FormConfirmation from "../../components/formConfirmation";
import DashboardViewList from "../../components/DashboardViewList";
import ProtectRouteList from "../../components/protectedRouteList";

const initialValues = {
  //Dashboard Views
  dashboards: [],
  //Client Route Links
  clientRouteList: [],
};
export const formSchema = {
  title: "User Role",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "xl", //xs, sm, xl  ....100%
  },
  initialValues,
  showSaveBar: false,
  apiEntity: "userRole",
  layout: {
    containers: [
      {
        key: "t1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Basic Info",
          orientation: "vertical",
          style: { minHeight: "400px" },
        },
        steps: [
          { key: "t1-1", label: "Basic Info", icon: IconSettings },
          { key: "t1-2", label: "API Access", icon: IconSettings },
          { key: "t1-3", label: "Dashboard View", icon: IconSettings },
          { key: "t1-4", label: "Page Access", icon: IconSettings },
          { key: "t1-5", label: "Confirm", icon: IconSettings },
        ],
      },
      {
        key: "section-info",
        parent: "t1-1",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "User Role Info",
          description: "Please fillin the basic info for the userRole",
          withBorder: false,
          ml: 10,
          mt: 0,
        },
      },

      {
        key: "section-confirm",
        parent: "t1-5",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Confirm",
          titleOrder: 5,
          description: "Please confirm the change",
          withBorder: false,
          ml: 10,
          mt: 0,
          mb: 10,
        },
      },
    ],
    fields: [
      {
        name: "name",
        component: TextInput,
        parent: "section-info",
        props: {
          required: true,
          placeholder: "Administrator",
          label: "English Name",
        },
      },
      {
        name: "description",
        component: Textarea,
        parent: "section-info",
        props: {
          required: true,
          placeholder: "description",
          label: "Description",
        },
      },
      {
        name: "links",
        component: APIRouteList,
        parent: "t1-2",
        props: {
          //   ml: 10,
        },
      },

      {
        name: "dashboards",
        component: DashboardViewList,
        parent: "t1-3",
        props: {
          //   ml: 10,
        },
      },

      {
        name: "clientRouteList",
        component: ProtectRouteList,
        parent: "t1-4",
        props: {
          //   ml: 10,
        },
      },

      {
        name: "confirm",
        component: FormConfirmation,
        parent: "section-confirm",
      },
    ],
  },
};
