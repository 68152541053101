import { Button, Group, TextInput, Text, Divider } from "@mantine/core";
import React from "react";
import _ from "lodash";
import { FormSectionCard } from "../layout/page";

const AssignRuleTester = ({ form }) => {
  const groupName = _.get(form.values, "name");
  return (
    <>
      <Group>
        <TextInput label="Width" size="xs" />
        <TextInput label="Depth" size="xs" />
        <TextInput label="Height" size="xs" />
      </Group>
      <Button mt="xl" size="xs">
        Test
      </Button>
    </>
  );
};

export default AssignRuleTester;
