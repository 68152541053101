import _ from "lodash";

import React from "react";

import { PageList } from "./pageList";

import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";
import JsonViewer from "./common/jsonViewer";

export default function ItemTransactionHistory({ form }) {
  const apiEntity = "itemTransaction";
  const item = _.get(form.values, "_id");
  const [getTableSchema] = useTableSchema();
  const [getFormSchema] = useFormSchema();

  const itemTransactionForm = getFormSchema("itemTransaction");
  itemTransactionForm.initialValues.item = item;

  return (
    <>
      {/* <JsonViewer src={form.values} /> */}
      {item && (
        <PageList
          apiEntity={apiEntity}
          preQueryNotEmpty={true}
          preQuery={{ item }}
          tableSchema={getTableSchema("itemTranscation")}
          formSchema={itemTransactionForm}
          hideActionButton={true}
          hideSearchBar={true}
          showMinAddButton={true}
          withPaper={false}
          tablePadding={0}
          pt={0}
          px={0}
          postSubmit={() => {
            form.reload();
          }}
          postDelete={() => {
            form.reload();
          }}
        ></PageList>
      )}
    </>
  );
}
