import { Chip, Text, Title, Group } from "@mantine/core";
import _ from "lodash";

const Chips = ({
  form,
  name,
  label,
  description,
  defaultValue,
  multiple = false,
  labelOrder = 5,
  items,
  ...props
}) => {
  const value = () => _.get(form.values, name) || defaultValue;

  const handleChange = (v) => {
    form.setFieldValue(name, v);
  };

  return (
    <>
      {/* <ReactJson src={ { value: value()}}  style={{background:'white'}} collapsed name='Chips'/> */}
      {/* <Title order={labelOrder}> {label}</Title> */}
      {/* <Text size="xs" color="dimmed" mb="xl"> {description}</Text> */}
      {label && (
        <Text size="md" pb={"xs"}>
          {label}
        </Text>
      )}
      <Chip.Group value={value()} onChange={handleChange} multiple={multiple}>
        <Group justify="start">
          {items?.length > 0 &&
            items.map((item) => (
              <Chip key={item.value} value={item.value}>
                {item.label}{" "}
              </Chip>
            ))}
        </Group>
      </Chip.Group>
    </>
  );
};

export default Chips;
