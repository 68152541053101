import React, { useState } from "react";
import {
  Button,
  Group,
  Popover,
  TextInput,
  Modal,
  Text,
  ScrollArea,
  ActionIcon,
  Tooltip,
} from "@mantine/core";
import {
  IconArrowDownBar,
  IconDeviceFloppy,
  IconDotsVertical,
  IconDownload,
} from "@tabler/icons-react";
import { useServerApi } from "../hooks/useServerApi";
import { useDisclosure } from "@mantine/hooks";
import _ from "lodash";

const TemplateSaveLoadButtons = ({
  form,
  name,
  templateCategory,
  onLoadTemplate,
  currentContent,
}) => {
  const [saveTemplateOpen, setSaveTemplateOpen] = useState(false);
  const [templateName, setTemplateName] = useState();
  const [
    templateLoaderOpened,
    { open: openTemplateLoader, close: closeTemplateLoader },
  ] = useDisclosure(false);

  const [templates, setTemplates] = useState([]);
  const [api] = useServerApi();

  const saveTemplate = async () => {
    setSaveTemplateOpen(false);
    const res = await api.add({
      apiEntity: "template",
      values: {
        category: templateCategory,
        name: templateName,
        content: currentContent,
      },
    });
    console.log(res);
  };

  const loadTemplate = async () => {
    const res = await api.search({
      apiEntity: "template",
      searchQuery: { category: templateCategory },
      pageSize: 1000,
      sort: { by: "name", order: "asc" },
    });
    // if (_.isEmpty(res.docs)) return;
    setTemplates(res.docs);
    openTemplateLoader();
  };

  const onRemoveTemplate = async (template) => {
    const confirm = window.confirm(
      "Are you sure to remove this template?",
      template.name
    );
    if (!confirm) return;
    const res = await api.removeById({
      apiEntity: "template",
      id: template._id,
    });
    loadTemplate();
  };

  return (
    <>
      <Modal
        opened={templateLoaderOpened}
        onClose={closeTemplateLoader}
        title={`Load ${templateCategory} Template`}
        scrollAreaComponent={ScrollArea.Autosize}
      >
        {/* Modal content */}
        {_.isEmpty(templates) && <Text>There are no templates.</Text>}
        {templates.map((t, index) => (
          <Group justify="space-between" mb={"md"}>
            <Text>{t.name}</Text>
            <Group gap={10}>
              <Button
                variant="default"
                size="xs"
                onClick={() => {
                  onLoadTemplate(templates[index]);
                  closeTemplateLoader();
                }}
              >
                Select
              </Button>
              <Button
                variant="default"
                size="xs"
                c="red"
                onClick={() => onRemoveTemplate(t)}
              >
                Remove
              </Button>
            </Group>
          </Group>
        ))}
      </Modal>
      <Popover
        width={300}
        trapFocus
        position="bottom"
        withArrow
        shadow="md"
        opened={saveTemplateOpen}
        onChange={setSaveTemplateOpen}
      >
        <Popover.Target>
          <Tooltip label="Save Template">
            <ActionIcon
              variant="default"
              aria-label="Save Template"
              onClick={() => setSaveTemplateOpen((o) => !o)}
            >
              <IconDeviceFloppy
                style={{ width: "70%", height: "70%" }}
                stroke={1.5}
              />
            </ActionIcon>
          </Tooltip>
        </Popover.Target>
        <Popover.Dropdown>
          <Group align="end">
            <TextInput
              label="Template Name"
              placeholder="Name"
              size="xs"
              onChange={(e) => setTemplateName(e.currentTarget.value)}
            />
            <Button variant="default" size="xs" onClick={saveTemplate}>
              Save
            </Button>
          </Group>
        </Popover.Dropdown>
      </Popover>
      <Tooltip label="Load Template">
        <ActionIcon variant="default" onClick={loadTemplate}>
          <IconDownload style={{ width: "70%", height: "70%" }} stroke={1.5} />
        </ActionIcon>
      </Tooltip>
    </>
  );
};

export default TemplateSaveLoadButtons;
