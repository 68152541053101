import { TextInput } from "@mantine/core";
import React, { useEffect } from "react";

import _ from "lodash";
import { useServerApi } from "../hooks/useServerApi";

const UniqueTextInput = ({
  form,
  name,
  uniqueEntity,
  errorMessage = "Not unqiue",
  ...props
}) => {
  const value = _.get(form.values, name);
  const id = _.get(form.values, "_id");

  const [api] = useServerApi();

  const checkDuplicate = async () => {
    if (_.isEmpty(value)) return;
    const result = await api.search({
      apiEntity: uniqueEntity,
      searchQuery: { [name]: value?.trim() },
    });

    if (!_.isEmpty(result.docs) && id !== result.docs[0]._id) {
      form.setFieldError(name, errorMessage);
    }
  };

  useEffect(() => {
    checkDuplicate();
  }, [value]);

  return <TextInput {...props}></TextInput>;
};

export default UniqueTextInput;
