import React from "react";
import { Text, List, Checkbox, Group } from "@mantine/core";
import _ from "lodash";
import { IconAlertTriangle } from "@tabler/icons-react";

const FormConfirmation = ({
  form,
  name,
  size = "xs",
  successText = ["Thank you."],
  needCheckBox = false,
  checkBoxText = "Please confirm the above information is correct.",
}) => {
  const showErrors = (errors) => {
    return (
      <>
        <Text size={size} mb="xl">
          There are some errors. Please go back to edit.
        </Text>
        <List>
          {Object.keys(errors).map((key) => (
            <List.Item>
              <Text size={size}>{errors[key]}</Text>
            </List.Item>
          ))}
        </List>
      </>
    );
  };

  return (
    <>
      {/* {form.isValid() ? "Valid" : "Not Valid"} */}
      {/* <ReactJson src={form.errors} style={{ background: "white" }} /> */}
      {needCheckBox && (
        <Group mb={"xl"}>
          <Checkbox />
          <Text size={"xs"}>{checkBoxText}</Text>
        </Group>
      )}
      {form.isValid() && (
        <List>
          {successText?.map((txt, index) => (
            <List.Item key={index}>
              <Text size={size}>{txt}</Text>
            </List.Item>
          ))}
        </List>
      )}
      {!form.isValid() && _.isEmpty(form.errors) && (
        <Group>
          <IconAlertTriangle size={15} />
          <Text size={size}>
            {"Please go back to check all required fields in the form."}
          </Text>
        </Group>
      )}

      {!_.isEmpty(form.errors) && showErrors(form.errors)}
    </>
  );
};

export default FormConfirmation;
