import React, { useState } from "react";
import { Modal, Text } from "@mantine/core";
import { Button, Checkbox, Group, List, Title } from "@mantine/core";

const ConfirmationModal = ({
  modalTitle,
  opened,
  onClose,
  size,
  withCloseButton = false,
  title,
  confirmList,
  onConfirm,
  agreeLabel = "I agree to confirm",
}) => {
  const [checked, setChecked] = useState(false);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      withCloseButton={withCloseButton}
      title={modalTitle}
      size={size}
    >
      <Title order={5}>{title}</Title>
      <List mt={"xl"}>
        {confirmList.map((l, index) => (
          <List.Item key={index}>
            <Text size={"sm"}>{l}</Text>
          </List.Item>
        ))}
      </List>

      <Checkbox
        my="xl"
        label={agreeLabel}
        size="xs"
        checked={checked}
        onChange={(event) => setChecked(event.currentTarget.checked)}
      />

      <Group position="right">
        <Button disabled={!checked} onClick={onConfirm}>
          Confirm
        </Button>
      </Group>
    </Modal>
  );
};

export default ConfirmationModal;
