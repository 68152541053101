import React from "react";
import { Text } from "@mantine/core";
import _ from "lodash";
import { useCellRender } from "../hooks/useCellRender";

const DummyWidget = ({ form, name, dummy, dummyRender }) => {
  const [cellRender] = useCellRender();

  const renderDummy = () => {
    if (!dummyRender) return;
    return dummyRender(form.values, cellRender);
  };
  return (
    <>
      {dummyRender && renderDummy()}
      {dummy && dummy}
    </>
  );
};

export default DummyWidget;
