import React, { forwardRef, useEffect, useState } from "react";
import { useServerApi } from "../hooks/useServerApi";
import {
  MultiSelect,
  MultiSelectProps,
  Select,
  Box,
  CloseButton,
  SelectItemProps,
  MultiSelectValueProps,
  Badge,
  Group,
} from "@mantine/core";
import _ from "lodash";

//Selected Item Badges
function Item({ value, label, onRemove, ...others }) {
  return (
    <div {...others} key={value}>
      <Box
        sx={(theme) => ({
          display: "flex",
          cursor: "default",
          alignItems: "center",

          backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
          border: `1px solid ${
            theme.colorScheme === "dark"
              ? theme.colors.dark[7]
              : theme.colors.gray[4]
          }`,
          paddingLeft: 10,
          borderRadius: 4,
        })}
      >
        <Box sx={{ lineHeight: 1, fontSize: 12 }}>{label}</Box>
        <CloseButton
          onMouseDown={() => onRemove(value)}
          variant="transparent"
          size={22}
          iconSize={14}
          tabIndex={-1}
        />
      </Box>
    </div>
  );
}

function MultiRemoteSelect({
  form,
  name,
  label,

  apiEntity,
  valueField = "_id",
  labelField = "code",
  preQuery,
  searchFields = ["code", "name"],
  sort,
  labelRender,
  placeholder = "Pick One",
  pageSize = 10,
  onDataChange = null,
  ...props
}) {
  const [options, setOptions] = useState([]); //Converted to Options
  const [data, setData] = useState([]); //Orginal data sets

  const [searchQuery, setSearchQuery] = useState({});
  const [api] = useServerApi();

  const currentPage = 1; //Always display first page
  const [fieldValue, setFieldValue] = useState([]); //selected value

  const formValue = _.get(form?.values, name) ?? [];

  const getLabel = (data) => {
    // console.log("Getlabel multi", name, data);
    if (labelRender) return labelRender(data);
    if (labelField) return _.get(data, labelField);
    return "";
  };

  const getLabelByValue = (v) => {
    const o = options.find((o) => o.value === v);
    if (o) return o.label;

    const fv = formValue.find((fv) => fv[valueField] === v[valueField]);
    if (fv) return getLabel(fv);
  };

  const fetchData = async () => {
    try {
      const result1 = await api.search({
        apiEntity,
        pageSize,
        currentPage,
        sort,
        searchQuery,
      });
      const rows = result1.docs;
      // console.log(rows, data, _.uniqBy([...data, ...rows], "_id"));
      // setData(_.uniqBy([...data, ...rows], "_id"));

      const o1 =
        rows.map((r) => ({
          value: _.get(r, valueField),
          label: getLabel(r),
        })) || [];

      // await fetchFieldValueData();

      // console.log("fetchFieldValueData1 multi", name, result1);

      if (!formValue) return setOptions(o1);

      let result2 = await api.search({
        apiEntity,
        pageSize,
        currentPage: 1,
        sort,
        searchQuery: { [valueField]: { $in: formValue } },
      });
      // console.log("fetchFieldValueData2 multi", name, result2);

      const rows2 = result2.docs;

      let o2 =
        rows2?.map((r) => ({
          value: _.get(r, valueField),
          label: getLabel(r),
        })) || [];

      setOptions(_.uniqBy([...o1, ...o2], "value"));
    } catch (e) {
      //	setOptions([]);
      //	setData([]);
    }
  };

  /**
   * @function buildSearchQuery
   * @description build the search query base on the search Text and fields want to search
   * @param {*} searchText
   * @param {*} searchFields
   * @returns
   */
  const buildSearchQuery = (searchText, searchFields) => {
    let searchArr = searchFields?.map((field) => ({
      [field]: { $regex: searchText, $options: "i" },
    }));

    if (!preQuery) return { $or: searchArr };
    return { $and: [preQuery, { $or: searchArr }] };
  };

  /**
   * @function handleSearchChange
   * @description handle the search changed => rebuild search query when
   * @param {*} searchText
   */
  const handleSearchChange = (searchText) => {
    let query = buildSearchQuery(searchText, searchFields);
    setSearchQuery(query);
  };

  /**
   * @function handleChange
   * @description
   * @param {*} value
   * @returns
   */
  const handleChange = (value) => {
    if (form) form.setFieldValue(name, _.uniq([...formValue, value]));
    if (onDataChange) onDataChange(_.uniq([...formValue, value]));
  };

  const onRemove = (v) => {
    form.setFieldValue(name, [...formValue.filter((value) => value !== v)]);
  };

  // useEffect(() => {
  // 	form.setFieldValue(name, fieldValue);

  // }, [fieldValue]);

  useEffect(() => {
    fetchData();
  }, [searchQuery]);

  return (
    <span>
      {/* name:{name} */}
      {/* {
				<ReactJson
					src={formValue}
					name="formValue"
					collapsed
					style={{ background: "white" }}
				/>
			}
			{
				<ReactJson
					src={fieldValue}
					name="fieldValue"
					collapsed
					style={{ background: "white" }}
				/>
			}

			{
				<ReactJson
					src={options} //{form.values[name]}
					name="options"
					collapsed
					style={{ background: "white" }}
				/>
			} */}
      <Select
        label={label}
        // value={fieldValue}
        data={options}
        limit={pageSize}
        searchable
        onChange={handleChange}
        onSearchChange={handleSearchChange}
        // defaultValue={}
        placeholder={placeholder}
        {...props}
      />
      {/* <TextInput onChange={handleChange} /> */}
      <Group spacing={"xs"} my="sm">
        {formValue.map((v, index) =>
          Item({
            value: v,
            label: getLabelByValue(v),
            onRemove: onRemove,
          })
        )}
      </Group>
    </span>
  );
}

export default MultiRemoteSelect;
