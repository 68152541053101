import React, { useState, useCallback, useEffect } from "react";
import { useServerApi } from "../hooks/useServerApi";
import { Group, Switch, Text } from "@mantine/core";
import _ from "lodash";

import { routes } from "../data/routes";

//Protected Route List
const ProjectRouteList = ({ form, name, ...props }) => {
  const [selectedRoutes, setSelectedRoutes] = useState([]);
  const userRole = form.values;
  const protectRoutes = routes.filter((r) => r.private);

  useEffect(() => {
    if (!userRole.clientRouteList) return;
    const selected = protectRoutes.filter((r) =>
      userRole.clientRouteList.includes(r.id)
    );
    setSelectedRoutes(selected);
  }, [userRole]);

  const changedSelected = (id, action) => {
    let newRoutes = [];
    if (action === "add") {
      userRole.clientRouteList.push(id);
      newRoutes = [...userRole.clientRouteList];
    } else {
      newRoutes = userRole.clientRouteList.filter((d) => d != id);
    }
    //Set Form values
    _.set(form.values, "clientRouteList", newRoutes);

    //Update UI
    const selected = protectRoutes.filter((v) => newRoutes.includes(v.id));
    setSelectedRoutes(selected);
    return;
  };

  const setChecked = (checked, id) => {
    if (!userRole.clientRouteList || !_.isArray(userRole.clientRouteList))
      return;
    if (!userRole.clientRouteList.includes(id) && checked) {
      return changedSelected(id, "add");
    }
    if (userRole.clientRouteList.includes(id) && !checked) {
      return changedSelected(id, "remove");
    }
  };

  return (
    <>
      {!_.isEmpty(protectRoutes) &&
        protectRoutes.map((route) => (
          <Group justify="space-between" style={{ width: "100%" }}>
            <div style={{ marginBottom: 20 }}>
              <Group>
                <Text fontWeight={800} size="sm">
                  {route.description}
                </Text>
              </Group>
              <Text size={"xs"} color="dimmed">
                {`${route.link}`}
              </Text>
            </div>
            <Switch
              checked={selectedRoutes.find((r) => r.id === route.id)}
              onChange={(event) =>
                setChecked(event.currentTarget.checked, route.id)
              }
            ></Switch>
          </Group>
        ))}
    </>
  );
};

export default ProjectRouteList;
