import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil, IconTrash } from "@tabler/icons-react";

export const ItemTransactionSchema = (cellRender) => {
  return {
    showActionColumn: true,
    columns: [
      {
        field: "transactionDate",
        headerName: "Date",
        sortable: true,
        cellRender: (col, data) => moment(data.transactionDate).format("LL"),
      },
      {
        field: "transactionType",
        headerName: "Type",
        sortable: true,
      },
      {
        field: "item.name",
        headerName: "Item",
        sortable: true,
      },

      {
        field: "quantity",
        headerName: "Quantity",
        sortable: true,
      },

      {
        field: "cost",
        headerName: "Total Cost",
        sortable: true,
      },

      {
        field: "remark",
        headerName: "Remark",
        sortable: true,
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
          {
            name: "delete",
            icon: IconTrash,
            props: {
              //   color:"red"
            },
          },
        ],
      },
    ],
  };
};
