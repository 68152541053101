import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil } from "@tabler/icons-react";

export const ClientStatementHistoryTable = (cellRender, { clientId }) => {
  return {
    preQuery: { client: clientId },
    searchableFields: ["code", "project.name", "status"],

    columns: [
      {
        field: "Start Date",
        filter: true,
        headerName: "Date",
        cellRender: (col, project) => {
          if (!project.duration || !project.duration.start) return "-";
          return moment(project.duration.start).format("ll");
        },
      },
      {
        field: "code",
        filter: true,
        cellRender: (col, project) => (
          <a
            href={`/project?id=${project._id}&mode=${"edit"}`}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            {project.code}
          </a>
        ),
      },
      { field: "projectType.name", filter: true, headerName: "Type" },
      {
        field: "totalReceivable",
        filter: true,
        headerName: "Total",
        cellRender: (col, project) =>
          cellRender.Currency(project.totalReceivable),
      },
      {
        field: "Received Amount",
        filter: true,
        headerName: "Received",
        cellRender: (col, project) =>
          cellRender.Currency(project.receivedAmount),
      },

      {
        field: "status",
        filter: true,
        cellRender: (col, project) => cellRender.Order.status(project.status),
      },
    ],
  };
};
