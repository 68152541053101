export const lineData = [
  {
    id: "Orders",
    data: [
      {
        x: "2022-01",
        y: 60,
      },
      {
        x: "2022-02",
        y: 87,
      },
      {
        x: "2022-03",
        y: 125,
      },
      {
        x: "2022-04",
        y: 208,
      },
      {
        x: "2022-05",
        y: 240,
      },
      {
        x: "2022-06",
        y: 173,
      },
      {
        x: "2022-07",
        y: 281,
      },
      {
        x: "2022-08",
        y: 192,
      },
      {
        x: "2022-09",
        y: 147,
      },
      {
        x: "2022-10",
        y: 176,
      },
      {
        x: "2022-12",
        y: 135,
      },
      {
        x: "2023-01",
        y: 291,
      },
    ],
  },
];

export const pieData = [
  {
    id: "ZI FSSA China Growth A",
    label: "FNUSD",
    value: 190,
    color: "hsl(165, 70%, 50%)",
  },
  {
    id: "ZI JP Morgan Asean Fund",
    label: "JCUSD",
    value: 229,
    color: "hsl(24, 70%, 50%)",
  },
  {
    id: "ZI BGF World Technology Fund A2 Acc USD",
    label: "K2USD",
    value: 419,
    color: "hsl(99, 70%, 50%)",
  },
  {
    id: "ZI Fidelity Sustainable US Dollar",
    label: "USDKL",
    value: 501,
    color: "hsl(201, 70%, 50%)",
  },
  {
    id: "ZI FSSA Asian Equity Plus ACC",
    label: "FOUSD",
    value: 324,
    color: "hsl(339, 70%, 50%)",
  },
];

export const barData = [
  {
    period: "2022-Q1",
    GI: 39,
    LIFE: 50,
    MPF: 20,
  },
  {
    period: "2022-Q2",
    GI: 49,
    LIFE: 76,
    MPF: 40,
  },
  {
    period: "2022-Q3",
    GI: 99,
    LIFE: 96,
    MPF: 80,
  },

  {
    period: "2022-Q4",
    GI: 159,
    LIFE: 162,
    MPF: 125,
  },
];

export const barData2 = [
  {
    _id: "202303",
    amount: 187,
  },
  {
    _id: "202304",
    amount: 300,
  },
  {
    _id: "202305",
    amount: 300,
  },
  {
    _id: "202306",
    amount: 300,
  },
  {
    _id: "202307",
    amount: 300,
  },
  {
    _id: "202308",
    amount: 300,
  },
  {
    _id: "202309",
    amount: 300,
  },
  {
    _id: "202310",
    amount: 300,
  },
  {
    _id: "202311",
    amount: 300,
  },
  {
    _id: "202312",
    amount: 300,
  },
  {
    _id: "202401",
    amount: 300,
  },
  {
    _id: "202402",
    amount: 230,
  },
  {
    _id: "202403",
    amount: 160,
  },
];

export const treemapData = {
  name: "Products",
  color: "hsl(98, 70%, 50%)",
  children: [
    {
      name: "LIFE",

      children: [
        {
          name: "Term Life",
          children: [
            {
              name: "MEUR",
              loc: 118150,
            },
            {
              name: "TLL2",
              loc: 76033,
            },
            {
              name: "TLL3",
              loc: 22532,
            },
            {
              name: "TLL4",
              loc: 59552,
            },
          ],
        },
        {
          name: "Medical",
          children: [
            {
              name: "M1",
              loc: 118150,
            },
            {
              name: "M2",
              loc: 76033,
            },
            {
              name: "M3",
              loc: 22532,
            },
            {
              name: "M4",
              loc: 59552,
            },
          ],
        },
        {
          name: "Accident",
          children: [
            {
              name: "M1",
              loc: 118150,
            },
            {
              name: "M2",
              loc: 76033,
            },
            {
              name: "M3",
              loc: 22532,
            },
            {
              name: "M4",
              loc: 59552,
            },
          ],
        },
        {
          name: "VHIS",
          children: [
            {
              name: "M1",
              loc: 118150,
            },
            {
              name: "M2",
              loc: 76033,
            },
            {
              name: "M3",
              loc: 22532,
            },
            {
              name: "M4",
              loc: 59552,
            },
          ],
        },
      ],
    },
    {
      name: "MPF",

      children: [
        {
          name: "Individual",
          children: [
            {
              name: "I1",
              loc: 22340,
            },
            {
              name: "I2",
              loc: 52648,
            },
          ],
        },
        {
          name: "Company",
          children: [
            {
              name: "C1",
              loc: 57830,
            },
            {
              name: "C3",
              loc: 33291,
            },
            {
              name: "C3",
              loc: 38290,
            },
          ],
        },
      ],
    },
    {
      name: "GI",

      children: [
        {
          name: "Fire",
          children: [
            {
              name: "randomize",
              loc: 101361,
            },
            {
              name: "resetClock",

              loc: 106237,
            },
          ],
        },
        {
          name: "Home",
          children: [
            {
              name: "noop",
              loc: 58768,
            },
            {
              name: "tick",
              loc: 20204,
            },
          ],
        },
        {
          name: "Car",
          children: [
            {
              name: "forceGC",

              loc: 87674,
            },
            {
              name: "stackTrace",

              loc: 12626,
            },
            {
              name: "dbg",

              loc: 61377,
            },
          ],
        },

        {
          name: "Construction",
          children: [
            {
              name: "forceGC",

              loc: 17674,
            },
            {
              name: "stackTrace",

              loc: 24626,
            },
            {
              name: "dbg",

              loc: 31377,
            },
          ],
        },
      ],
    },
  ],
};

export const calendarData = [
  {
    value: 284,
    day: "2022-11-23",
  },
  {
    value: 80,
    day: "2022-05-07",
  },
  {
    value: 352,
    day: "2022-02-21",
  },
  {
    value: 293,
    day: "2022-10-06",
  },
  {
    value: 138,
    day: "2021-07-28",
  },
  {
    value: 365,
    day: "2022-09-02",
  },
  {
    value: 214,
    day: "2022-11-26",
  },
  {
    value: 162,
    day: "2018-04-17",
  },
  {
    value: 292,
    day: "2022-07-15",
  },
  {
    value: 303,
    day: "2022-09-27",
  },
  {
    value: 361,
    day: "2023-08-05",
  },
  {
    value: 216,
    day: "2022-05-12",
  },
  {
    value: 378,
    day: "2022-12-19",
  },
  {
    value: 229,
    day: "2022-05-01",
  },
  {
    value: 140,
    day: "2023-07-07",
  },
  {
    value: 323,
    day: "2022-09-22",
  },
  {
    value: 124,
    day: "2018-05-06",
  },
  {
    value: 61,
    day: "2022-01-16",
  },
  {
    value: 211,
    day: "2018-04-15",
  },
  {
    value: 245,
    day: "2022-09-18",
  },
  {
    value: 127,
    day: "2021-06-01",
  },
  {
    value: 249,
    day: "2023-10-07",
  },
  {
    value: 276,
    day: "2023-09-27",
  },
  {
    value: 140,
    day: "2022-08-25",
  },
  {
    value: 87,
    day: "2022-06-12",
  },
  {
    value: 213,
    day: "2021-01-29",
  },
  {
    value: 336,
    day: "2021-07-27",
  },
  {
    value: 49,
    day: "2021-05-11",
  },
  {
    value: 10,
    day: "2018-01-25",
  },
  {
    value: 269,
    day: "2021-07-20",
  },
  {
    value: 212,
    day: "2021-10-27",
  },
  {
    value: 57,
    day: "2018-03-09",
  },
  {
    value: 387,
    day: "2023-10-31",
  },
  {
    value: 391,
    day: "2021-10-20",
  },
  {
    value: 140,
    day: "2022-07-12",
  },
  {
    value: 331,
    day: "2023-09-16",
  },
  {
    value: 390,
    day: "2023-10-22",
  },
  {
    value: 382,
    day: "2023-10-06",
  },
  {
    value: 255,
    day: "2022-09-04",
  },
  {
    value: 323,
    day: "2023-10-27",
  },
  {
    value: 24,
    day: "2018-03-29",
  },
  {
    value: 211,
    day: "2018-02-06",
  },
  {
    value: 363,
    day: "2022-03-23",
  },
  {
    value: 277,
    day: "2022-12-22",
  },
  {
    value: 40,
    day: "2021-07-22",
  },
  {
    value: 265,
    day: "2023-04-23",
  },
  {
    value: 185,
    day: "2018-04-10",
  },
  {
    value: 53,
    day: "2023-09-01",
  },
  {
    value: 61,
    day: "2022-04-03",
  },
  {
    value: 344,
    day: "2021-05-22",
  },
  {
    value: 218,
    day: "2022-04-12",
  },
  {
    value: 152,
    day: "2021-04-02",
  },
  {
    value: 275,
    day: "2021-12-15",
  },
  {
    value: 184,
    day: "2022-04-28",
  },
  {
    value: 356,
    day: "2023-07-14",
  },
  {
    value: 357,
    day: "2023-05-07",
  },
  {
    value: 253,
    day: "2021-02-02",
  },
  {
    value: 46,
    day: "2022-03-29",
  },
  {
    value: 221,
    day: "2021-08-08",
  },
  {
    value: 88,
    day: "2023-11-15",
  },
  {
    value: 147,
    day: "2021-04-13",
  },
  {
    value: 371,
    day: "2021-10-31",
  },
  {
    value: 349,
    day: "2023-09-21",
  },
  {
    value: 375,
    day: "2021-08-18",
  },
  {
    value: 84,
    day: "2023-06-13",
  },
  {
    value: 169,
    day: "2021-07-12",
  },
  {
    value: 288,
    day: "2021-09-02",
  },
  {
    value: 97,
    day: "2022-03-10",
  },
  {
    value: 160,
    day: "2023-04-07",
  },
  {
    value: 133,
    day: "2018-02-18",
  },
  {
    value: 315,
    day: "2018-05-24",
  },
  {
    value: 77,
    day: "2021-10-22",
  },
  {
    value: 75,
    day: "2021-12-26",
  },
  {
    value: 386,
    day: "2022-03-03",
  },
  {
    value: 379,
    day: "2022-09-28",
  },
  {
    value: 177,
    day: "2018-08-10",
  },
  {
    value: 85,
    day: "2018-03-12",
  },
  {
    value: 137,
    day: "2018-01-15",
  },
  {
    value: 211,
    day: "2021-08-28",
  },
  {
    value: 200,
    day: "2023-06-24",
  },
  {
    value: 167,
    day: "2021-10-19",
  },
  {
    value: 247,
    day: "2023-04-20",
  },
  {
    value: 117,
    day: "2022-05-19",
  },
  {
    value: 156,
    day: "2023-11-29",
  },
  {
    value: 176,
    day: "2018-01-18",
  },
  {
    value: 151,
    day: "2022-06-16",
  },
  {
    value: 234,
    day: "2023-08-16",
  },
  {
    value: 41,
    day: "2022-07-29",
  },
  {
    value: 61,
    day: "2018-07-22",
  },
  {
    value: 120,
    day: "2022-03-31",
  },
  {
    value: 221,
    day: "2023-11-10",
  },
  {
    value: 191,
    day: "2022-10-18",
  },
  {
    value: 225,
    day: "2018-02-08",
  },
  {
    value: 78,
    day: "2021-08-19",
  },
  {
    value: 321,
    day: "2022-01-22",
  },
  {
    value: 203,
    day: "2023-06-29",
  },
  {
    value: 378,
    day: "2022-12-05",
  },
  {
    value: 228,
    day: "2022-04-13",
  },
  {
    value: 142,
    day: "2018-07-27",
  },
  {
    value: 244,
    day: "2018-04-06",
  },
  {
    value: 133,
    day: "2018-01-09",
  },
  {
    value: 243,
    day: "2023-05-02",
  },
  {
    value: 346,
    day: "2023-09-23",
  },
  {
    value: 99,
    day: "2018-06-27",
  },
  {
    value: 342,
    day: "2021-01-12",
  },
  {
    value: 163,
    day: "2022-11-28",
  },
  {
    value: 66,
    day: "2022-06-14",
  },
  {
    value: 196,
    day: "2021-07-29",
  },
  {
    value: 8,
    day: "2023-09-02",
  },
  {
    value: 76,
    day: "2021-02-08",
  },
  {
    value: 346,
    day: "2021-05-19",
  },
  {
    value: 285,
    day: "2021-05-17",
  },
  {
    value: 50,
    day: "2022-01-03",
  },
  {
    value: 43,
    day: "2021-12-05",
  },
  {
    value: 287,
    day: "2023-10-02",
  },
  {
    value: 118,
    day: "2018-03-13",
  },
  {
    value: 316,
    day: "2023-08-09",
  },
  {
    value: 60,
    day: "2023-10-16",
  },
  {
    value: 90,
    day: "2023-06-08",
  },
  {
    value: 134,
    day: "2022-10-08",
  },
  {
    value: 399,
    day: "2023-07-25",
  },
  {
    value: 147,
    day: "2022-09-14",
  },
  {
    value: 103,
    day: "2021-01-02",
  },
  {
    value: 243,
    day: "2023-08-13",
  },
  {
    value: 168,
    day: "2022-08-02",
  },
  {
    value: 231,
    day: "2023-06-18",
  },
  {
    value: 372,
    day: "2021-01-24",
  },
  {
    value: 83,
    day: "2021-09-14",
  },
  {
    value: 305,
    day: "2018-03-05",
  },
  {
    value: 266,
    day: "2018-04-14",
  },
  {
    value: 200,
    day: "2023-06-11",
  },
  {
    value: 349,
    day: "2023-08-10",
  },
  {
    value: 355,
    day: "2023-12-10",
  },
  {
    value: 44,
    day: "2018-06-29",
  },
  {
    value: 167,
    day: "2022-10-13",
  },
  {
    value: 110,
    day: "2023-07-01",
  },
  {
    value: 23,
    day: "2022-05-02",
  },
  {
    value: 58,
    day: "2022-09-17",
  },
  {
    value: 43,
    day: "2018-07-13",
  },
  {
    value: 102,
    day: "2021-01-16",
  },
  {
    value: 59,
    day: "2023-08-21",
  },
  {
    value: 316,
    day: "2021-10-14",
  },
  {
    value: 307,
    day: "2018-07-16",
  },
  {
    value: 46,
    day: "2022-12-21",
  },
  {
    value: 272,
    day: "2021-12-31",
  },
  {
    value: 205,
    day: "2018-03-28",
  },
  {
    value: 260,
    day: "2022-02-05",
  },
  {
    value: 275,
    day: "2018-05-30",
  },
  {
    value: 272,
    day: "2018-02-24",
  },
  {
    value: 253,
    day: "2018-06-22",
  },
  {
    value: 73,
    day: "2022-08-16",
  },
  {
    value: 358,
    day: "2022-09-08",
  },
  {
    value: 142,
    day: "2022-11-12",
  },
  {
    value: 70,
    day: "2021-11-15",
  },
  {
    value: 110,
    day: "2021-02-10",
  },
  {
    value: 312,
    day: "2021-09-13",
  },
  {
    value: 25,
    day: "2023-11-27",
  },
  {
    value: 143,
    day: "2021-08-14",
  },
  {
    value: 37,
    day: "2021-02-23",
  },
  {
    value: 342,
    day: "2022-09-16",
  },
  {
    value: 333,
    day: "2021-05-23",
  },
  {
    value: 32,
    day: "2021-04-24",
  },
  {
    value: 338,
    day: "2021-01-25",
  },
  {
    value: 146,
    day: "2022-01-24",
  },
  {
    value: 246,
    day: "2021-06-25",
  },
  {
    value: 122,
    day: "2022-10-03",
  },
  {
    value: 70,
    day: "2021-08-30",
  },
  {
    value: 240,
    day: "2021-03-16",
  },
  {
    value: 207,
    day: "2022-03-09",
  },
  {
    value: 15,
    day: "2023-12-30",
  },
  {
    value: 366,
    day: "2023-10-23",
  },
  {
    value: 132,
    day: "2022-08-10",
  },
  {
    value: 198,
    day: "2018-06-07",
  },
  {
    value: 302,
    day: "2021-05-18",
  },
  {
    value: 389,
    day: "2022-05-14",
  },
  {
    value: 265,
    day: "2023-08-03",
  },
  {
    value: 255,
    day: "2021-12-24",
  },
  {
    value: 179,
    day: "2021-09-24",
  },
  {
    value: 374,
    day: "2023-08-08",
  },
  {
    value: 57,
    day: "2023-09-20",
  },
  {
    value: 276,
    day: "2021-04-07",
  },
  {
    value: 13,
    day: "2021-04-21",
  },
  {
    value: 348,
    day: "2023-07-24",
  },
  {
    value: 311,
    day: "2022-05-18",
  },
  {
    value: 127,
    day: "2021-02-18",
  },
  {
    value: 323,
    day: "2022-10-17",
  },
  {
    value: 277,
    day: "2021-11-10",
  },
  {
    value: 159,
    day: "2021-02-29",
  },
  {
    value: 11,
    day: "2021-08-20",
  },
  {
    value: 112,
    day: "2018-05-05",
  },
  {
    value: 180,
    day: "2022-04-06",
  },
  {
    value: 142,
    day: "2021-09-18",
  },
  {
    value: 220,
    day: "2022-10-29",
  },
  {
    value: 138,
    day: "2021-11-02",
  },
  {
    value: 115,
    day: "2023-05-21",
  },
  {
    value: 43,
    day: "2022-02-01",
  },
  {
    value: 170,
    day: "2018-08-11",
  },
  {
    value: 154,
    day: "2021-04-01",
  },
  {
    value: 49,
    day: "2022-01-07",
  },
  {
    value: 75,
    day: "2018-07-08",
  },
  {
    value: 151,
    day: "2021-08-29",
  },
  {
    value: 52,
    day: "2022-07-21",
  },
  {
    value: 89,
    day: "2018-06-02",
  },
  {
    value: 315,
    day: "2018-03-03",
  },
  {
    value: 184,
    day: "2022-07-18",
  },
  {
    value: 394,
    day: "2022-04-25",
  },
  {
    value: 370,
    day: "2018-02-14",
  },
  {
    value: 293,
    day: "2022-01-27",
  },
  {
    value: 157,
    day: "2018-01-31",
  },
  {
    value: 248,
    day: "2022-03-11",
  },
  {
    value: 9,
    day: "2018-05-04",
  },
  {
    value: 221,
    day: "2021-07-26",
  },
  {
    value: 246,
    day: "2021-09-09",
  },
  {
    value: 285,
    day: "2021-10-24",
  },
  {
    value: 89,
    day: "2023-06-16",
  },
  {
    value: 43,
    day: "2021-02-05",
  },
  {
    value: 5,
    day: "2023-12-04",
  },
  {
    value: 285,
    day: "2022-10-30",
  },
  {
    value: 364,
    day: "2023-07-23",
  },
  {
    value: 115,
    day: "2018-06-13",
  },
  {
    value: 300,
    day: "2023-09-06",
  },
  {
    value: 330,
    day: "2023-05-05",
  },
  {
    value: 302,
    day: "2023-05-16",
  },
  {
    value: 100,
    day: "2022-12-20",
  },
  {
    value: 69,
    day: "2022-08-19",
  },
  {
    value: 15,
    day: "2023-05-20",
  },
  {
    value: 337,
    day: "2018-06-24",
  },
  {
    value: 228,
    day: "2023-09-09",
  },
  {
    value: 130,
    day: "2021-11-07",
  },
  {
    value: 384,
    day: "2023-05-30",
  },
  {
    value: 52,
    day: "2022-02-10",
  },
  {
    value: 102,
    day: "2021-10-13",
  },
  {
    value: 261,
    day: "2022-09-29",
  },
  {
    value: 225,
    day: "2023-10-12",
  },
  {
    value: 339,
    day: "2023-10-18",
  },
  {
    value: 153,
    day: "2021-12-04",
  },
  {
    value: 109,
    day: "2023-04-26",
  },
  {
    value: 13,
    day: "2021-06-07",
  },
  {
    value: 189,
    day: "2022-03-28",
  },
  {
    value: 306,
    day: "2023-12-08",
  },
  {
    value: 354,
    day: "2018-07-28",
  },
  {
    value: 31,
    day: "2021-11-23",
  },
  {
    value: 361,
    day: "2022-09-24",
  },
  {
    value: 235,
    day: "2022-05-15",
  },
  {
    value: 187,
    day: "2022-01-12",
  },
  {
    value: 182,
    day: "2018-01-19",
  },
  {
    value: 97,
    day: "2021-02-22",
  },
  {
    value: 259,
    day: "2021-06-18",
  },
  {
    value: 208,
    day: "2021-05-16",
  },
  {
    value: 180,
    day: "2021-06-26",
  },
  {
    value: 118,
    day: "2022-06-01",
  },
  {
    value: 116,
    day: "2022-09-30",
  },
  {
    value: 265,
    day: "2021-05-31",
  },
  {
    value: 53,
    day: "2021-01-31",
  },
  {
    value: 293,
    day: "2023-06-26",
  },
  {
    value: 339,
    day: "2022-11-11",
  },
  {
    value: 52,
    day: "2018-05-16",
  },
  {
    value: 221,
    day: "2021-02-19",
  },
  {
    value: 129,
    day: "2023-06-01",
  },
  {
    value: 339,
    day: "2023-04-21",
  },
  {
    value: 166,
    day: "2022-06-11",
  },
  {
    value: 177,
    day: "2018-06-15",
  },
  {
    value: 303,
    day: "2023-07-03",
  },
  {
    value: 354,
    day: "2022-10-24",
  },
  {
    value: 166,
    day: "2022-09-15",
  },
  {
    value: 366,
    day: "2021-12-01",
  },
  {
    value: 352,
    day: "2021-03-09",
  },
  {
    value: 270,
    day: "2023-05-18",
  },
  {
    value: 71,
    day: "2022-11-17",
  },
  {
    value: 3,
    day: "2022-09-03",
  },
  {
    value: 344,
    day: "2021-12-07",
  },
  {
    value: 187,
    day: "2022-06-29",
  },
  {
    value: 283,
    day: "2023-10-09",
  },
  {
    value: 260,
    day: "2022-08-14",
  },
  {
    value: 95,
    day: "2022-03-02",
  },
  {
    value: 357,
    day: "2021-06-23",
  },
  {
    value: 88,
    day: "2018-02-25",
  },
  {
    value: 210,
    day: "2023-12-02",
  },
  {
    value: 278,
    day: "2022-01-18",
  },
  {
    value: 298,
    day: "2022-04-22",
  },
  {
    value: 115,
    day: "2022-01-28",
  },
  {
    value: 286,
    day: "2021-11-21",
  },
  {
    value: 226,
    day: "2022-08-05",
  },
  {
    value: 187,
    day: "2022-10-05",
  },
  {
    value: 347,
    day: "2021-06-22",
  },
  {
    value: 217,
    day: "2018-03-07",
  },
  {
    value: 106,
    day: "2021-09-27",
  },
  {
    value: 29,
    day: "2018-06-05",
  },
  {
    value: 238,
    day: "2022-11-03",
  },
  {
    value: 364,
    day: "2022-05-23",
  },
  {
    value: 145,
    day: "2021-11-24",
  },
  {
    value: 75,
    day: "2021-02-20",
  },
  {
    value: 395,
    day: "2022-11-22",
  },
  {
    value: 22,
    day: "2023-12-06",
  },
  {
    value: 203,
    day: "2023-08-12",
  },
  {
    value: 19,
    day: "2018-04-08",
  },
  {
    value: 335,
    day: "2021-02-24",
  },
  {
    value: 361,
    day: "2018-06-10",
  },
  {
    value: 38,
    day: "2023-06-09",
  },
  {
    value: 9,
    day: "2023-05-11",
  },
  {
    value: 377,
    day: "2023-06-17",
  },
  {
    value: 352,
    day: "2022-01-26",
  },
  {
    value: 18,
    day: "2022-12-27",
  },
  {
    value: 180,
    day: "2023-12-03",
  },
  {
    value: 345,
    day: "2023-09-25",
  },
  {
    value: 161,
    day: "2022-09-21",
  },
  {
    value: 247,
    day: "2022-08-23",
  },
  {
    value: 117,
    day: "2023-06-25",
  },
  {
    value: 226,
    day: "2022-04-04",
  },
  {
    value: 173,
    day: "2023-08-01",
  },
  {
    value: 259,
    day: "2018-05-22",
  },
  {
    value: 26,
    day: "2021-08-16",
  },
  {
    value: 175,
    day: "2022-06-15",
  },
  {
    value: 70,
    day: "2021-10-17",
  },
  {
    value: 182,
    day: "2023-06-15",
  },
  {
    value: 116,
    day: "2021-09-20",
  },
  {
    value: 163,
    day: "2021-04-03",
  },
  {
    value: 114,
    day: "2018-06-30",
  },
  {
    value: 30,
    day: "2023-06-20",
  },
  {
    value: 146,
    day: "2023-05-25",
  },
  {
    value: 27,
    day: "2023-10-15",
  },
  {
    value: 281,
    day: "2021-02-03",
  },
  {
    value: 130,
    day: "2018-05-13",
  },
  {
    value: 173,
    day: "2018-05-17",
  },
  {
    value: 52,
    day: "2023-08-14",
  },
  {
    value: 354,
    day: "2023-09-28",
  },
  {
    value: 108,
    day: "2021-07-05",
  },
  {
    value: 107,
    day: "2021-08-25",
  },
  {
    value: 271,
    day: "2023-07-10",
  },
  {
    value: 221,
    day: "2021-12-11",
  },
  {
    value: 321,
    day: "2023-07-12",
  },
  {
    value: 172,
    day: "2018-05-03",
  },
  {
    value: 144,
    day: "2018-04-18",
  },
  {
    value: 235,
    day: "2022-08-31",
  },
  {
    value: 128,
    day: "2018-08-03",
  },
  {
    value: 84,
    day: "2023-04-11",
  },
  {
    value: 216,
    day: "2018-07-06",
  },
  {
    value: 305,
    day: "2018-05-18",
  },
  {
    value: 8,
    day: "2022-07-07",
  },
  {
    value: 307,
    day: "2018-04-24",
  },
  {
    value: 27,
    day: "2023-05-14",
  },
  {
    value: 76,
    day: "2023-10-05",
  },
  {
    value: 122,
    day: "2021-09-15",
  },
  {
    value: 352,
    day: "2022-09-10",
  },
  {
    value: 270,
    day: "2022-10-23",
  },
  {
    value: 20,
    day: "2021-03-06",
  },
  {
    value: 241,
    day: "2018-06-01",
  },
  {
    value: 176,
    day: "2021-03-14",
  },
  {
    value: 190,
    day: "2021-08-06",
  },
  {
    value: 181,
    day: "2021-05-25",
  },
  {
    value: 87,
    day: "2022-06-20",
  },
  {
    value: 144,
    day: "2018-03-06",
  },
  {
    value: 302,
    day: "2023-10-17",
  },
  {
    value: 64,
    day: "2021-08-02",
  },
  {
    value: 123,
    day: "2021-11-26",
  },
  {
    value: 327,
    day: "2022-12-02",
  },
  {
    value: 185,
    day: "2022-03-12",
  },
  {
    value: 383,
    day: "2022-03-15",
  },
  {
    value: 16,
    day: "2021-10-30",
  },
  {
    value: 212,
    day: "2018-02-19",
  },
  {
    value: 161,
    day: "2018-05-20",
  },
  {
    value: 383,
    day: "2022-04-21",
  },
  {
    value: 300,
    day: "2022-06-27",
  },
  {
    value: 97,
    day: "2018-04-23",
  },
  {
    value: 26,
    day: "2021-07-13",
  },
  {
    value: 211,
    day: "2022-10-04",
  },
  {
    value: 305,
    day: "2022-07-14",
  },
  {
    value: 246,
    day: "2021-03-07",
  },
  {
    value: 99,
    day: "2021-08-01",
  },
  {
    value: 367,
    day: "2021-08-22",
  },
  {
    value: 131,
    day: "2023-06-07",
  },
  {
    value: 350,
    day: "2023-06-19",
  },
  {
    value: 129,
    day: "2022-09-06",
  },
  {
    value: 109,
    day: "2021-01-23",
  },
  {
    value: 113,
    day: "2023-04-17",
  },
  {
    value: 346,
    day: "2022-12-23",
  },
  {
    value: 372,
    day: "2018-04-04",
  },
  {
    value: 255,
    day: "2023-10-26",
  },
  {
    value: 374,
    day: "2021-08-05",
  },
  {
    value: 298,
    day: "2018-03-15",
  },
  {
    value: 85,
    day: "2021-01-01",
  },
  {
    value: 71,
    day: "2023-04-18",
  },
  {
    value: 315,
    day: "2022-06-30",
  },
  {
    value: 47,
    day: "2021-07-07",
  },
  {
    value: 147,
    day: "2018-02-03",
  },
  {
    value: 149,
    day: "2022-12-26",
  },
  {
    value: 325,
    day: "2023-08-15",
  },
  {
    value: 277,
    day: "2021-12-27",
  },
  {
    value: 197,
    day: "2023-10-30",
  },
  {
    value: 362,
    day: "2022-02-04",
  },
  {
    value: 124,
    day: "2018-06-03",
  },
  {
    value: 75,
    day: "2021-10-18",
  },
  {
    value: 222,
    day: "2022-12-16",
  },
  {
    value: 235,
    day: "2022-04-14",
  },
  {
    value: 381,
    day: "2021-05-03",
  },
  {
    value: 145,
    day: "2021-12-28",
  },
  {
    value: 15,
    day: "2018-05-12",
  },
  {
    value: 223,
    day: "2021-04-05",
  },
  {
    value: 327,
    day: "2023-08-02",
  },
  {
    value: 187,
    day: "2021-11-25",
  },
  {
    value: 109,
    day: "2023-10-08",
  },
  {
    value: 262,
    day: "2023-04-19",
  },
  {
    value: 67,
    day: "2023-07-05",
  },
  {
    value: 313,
    day: "2021-10-08",
  },
  {
    value: 294,
    day: "2021-03-04",
  },
  {
    value: 292,
    day: "2021-12-16",
  },
  {
    value: 211,
    day: "2022-10-11",
  },
  {
    value: 351,
    day: "2022-05-13",
  },
  {
    value: 28,
    day: "2022-05-10",
  },
  {
    value: 299,
    day: "2022-01-15",
  },
  {
    value: 263,
    day: "2023-04-03",
  },
  {
    value: 57,
    day: "2021-05-24",
  },
  {
    value: 391,
    day: "2022-10-21",
  },
  {
    value: 141,
    day: "2021-12-14",
  },
  {
    value: 357,
    day: "2021-01-17",
  },
  {
    value: 309,
    day: "2018-03-22",
  },
  {
    value: 197,
    day: "2023-04-29",
  },
  {
    value: 152,
    day: "2023-12-22",
  },
  {
    value: 25,
    day: "2018-04-12",
  },
  {
    value: 85,
    day: "2023-07-20",
  },
  {
    value: 278,
    day: "2021-03-11",
  },
  {
    value: 119,
    day: "2022-09-23",
  },
  {
    value: 393,
    day: "2018-02-17",
  },
  {
    value: 378,
    day: "2021-04-14",
  },
  {
    value: 201,
    day: "2022-03-22",
  },
  {
    value: 362,
    day: "2022-12-07",
  },
  {
    value: 292,
    day: "2021-02-01",
  },
  {
    value: 178,
    day: "2021-04-19",
  },
  {
    value: 94,
    day: "2021-09-01",
  },
  {
    value: 372,
    day: "2023-05-31",
  },
  {
    value: 124,
    day: "2021-10-16",
  },
  {
    value: 162,
    day: "2021-03-05",
  },
  {
    value: 33,
    day: "2021-01-10",
  },
  {
    value: 82,
    day: "2021-01-30",
  },
  {
    value: 397,
    day: "2022-11-02",
  },
  {
    value: 122,
    day: "2018-03-10",
  },
  {
    value: 373,
    day: "2018-06-28",
  },
  {
    value: 57,
    day: "2022-06-24",
  },
  {
    value: 27,
    day: "2018-01-03",
  },
  {
    value: 341,
    day: "2023-07-08",
  },
  {
    value: 241,
    day: "2021-09-19",
  },
  {
    value: 47,
    day: "2021-04-04",
  },
  {
    value: 229,
    day: "2022-05-08",
  },
  {
    value: 101,
    day: "2022-12-13",
  },
  {
    value: 310,
    day: "2022-10-25",
  },
  {
    value: 33,
    day: "2021-11-11",
  },
  {
    value: 127,
    day: "2023-04-13",
  },
  {
    value: 245,
    day: "2022-02-15",
  },
  {
    value: 262,
    day: "2021-09-26",
  },
  {
    value: 4,
    day: "2022-07-11",
  },
  {
    value: 233,
    day: "2022-04-16",
  },
  {
    value: 227,
    day: "2021-04-17",
  },
  {
    value: 143,
    day: "2021-11-03",
  },
  {
    value: 68,
    day: "2021-06-16",
  },
  {
    value: 317,
    day: "2022-01-23",
  },
  {
    value: 183,
    day: "2021-01-05",
  },
  {
    value: 136,
    day: "2018-07-20",
  },
  {
    value: 45,
    day: "2023-08-26",
  },
  {
    value: 280,
    day: "2023-09-30",
  },
  {
    value: 221,
    day: "2023-07-26",
  },
  {
    value: 87,
    day: "2021-08-15",
  },
  {
    value: 29,
    day: "2021-03-28",
  },
  {
    value: 28,
    day: "2022-12-01",
  },
  {
    value: 343,
    day: "2022-01-09",
  },
  {
    value: 398,
    day: "2022-08-06",
  },
  {
    value: 89,
    day: "2022-08-12",
  },
  {
    value: 192,
    day: "2021-06-09",
  },
  {
    value: 279,
    day: "2021-04-18",
  },
  {
    value: 67,
    day: "2018-07-09",
  },
  {
    value: 311,
    day: "2018-02-26",
  },
  {
    value: 64,
    day: "2023-06-03",
  },
  {
    value: 30,
    day: "2018-08-02",
  },
  {
    value: 72,
    day: "2022-09-26",
  },
  {
    value: 15,
    day: "2018-03-19",
  },
  {
    value: 130,
    day: "2022-04-15",
  },
  {
    value: 21,
    day: "2023-04-16",
  },
  {
    value: 368,
    day: "2023-11-19",
  },
  {
    value: 52,
    day: "2022-02-03",
  },
  {
    value: 10,
    day: "2022-02-09",
  },
  {
    value: 238,
    day: "2021-02-27",
  },
  {
    value: 308,
    day: "2023-04-08",
  },
  {
    value: 212,
    day: "2018-02-21",
  },
  {
    value: 315,
    day: "2021-01-27",
  },
  {
    value: 350,
    day: "2021-05-15",
  },
  {
    value: 303,
    day: "2022-01-04",
  },
  {
    value: 273,
    day: "2021-09-12",
  },
  {
    value: 270,
    day: "2018-01-07",
  },
  {
    value: 319,
    day: "2021-11-01",
  },
  {
    value: 255,
    day: "2023-12-26",
  },
  {
    value: 290,
    day: "2021-12-03",
  },
  {
    value: 253,
    day: "2023-12-01",
  },
  {
    value: 201,
    day: "2021-02-25",
  },
  {
    value: 1,
    day: "2018-03-14",
  },
  {
    value: 276,
    day: "2021-09-23",
  },
  {
    value: 126,
    day: "2022-03-16",
  },
  {
    value: 355,
    day: "2022-08-13",
  },
  {
    value: 208,
    day: "2021-06-27",
  },
  {
    value: 85,
    day: "2023-10-04",
  },
  {
    value: 109,
    day: "2021-10-11",
  },
  {
    value: 335,
    day: "2022-04-10",
  },
  {
    value: 395,
    day: "2023-08-24",
  },
  {
    value: 328,
    day: "2021-12-18",
  },
  {
    value: 277,
    day: "2022-05-09",
  },
  {
    value: 107,
    day: "2022-12-15",
  },
  {
    value: 96,
    day: "2023-07-02",
  },
  {
    value: 144,
    day: "2023-08-17",
  },
  {
    value: 392,
    day: "2021-02-17",
  },
  {
    value: 80,
    day: "2021-01-03",
  },
  {
    value: 37,
    day: "2021-02-12",
  },
  {
    value: 176,
    day: "2023-08-31",
  },
  {
    value: 289,
    day: "2022-10-22",
  },
  {
    value: 277,
    day: "2018-08-09",
  },
  {
    value: 291,
    day: "2018-02-12",
  },
  {
    value: 387,
    day: "2021-05-02",
  },
  {
    value: 139,
    day: "2023-05-23",
  },
  {
    value: 260,
    day: "2018-02-27",
  },
  {
    value: 396,
    day: "2023-11-05",
  },
  {
    value: 225,
    day: "2021-11-29",
  },
  {
    value: 392,
    day: "2022-08-08",
  },
  {
    value: 166,
    day: "2018-07-10",
  },
  {
    value: 94,
    day: "2018-07-19",
  },
  {
    value: 128,
    day: "2023-07-27",
  },
  {
    value: 249,
    day: "2021-06-06",
  },
  {
    value: 111,
    day: "2022-04-18",
  },
  {
    value: 170,
    day: "2023-11-04",
  },
  {
    value: 188,
    day: "2021-10-25",
  },
  {
    value: 322,
    day: "2021-11-28",
  },
  {
    value: 377,
    day: "2018-06-20",
  },
  {
    value: 86,
    day: "2022-08-24",
  },
  {
    value: 72,
    day: "2022-11-25",
  },
  {
    value: 218,
    day: "2022-06-05",
  },
  {
    value: 332,
    day: "2021-09-29",
  },
  {
    value: 221,
    day: "2021-08-09",
  },
  {
    value: 369,
    day: "2023-05-01",
  },
  {
    value: 14,
    day: "2022-06-04",
  },
  {
    value: 390,
    day: "2021-10-21",
  },
  {
    value: 135,
    day: "2022-07-13",
  },
  {
    value: 121,
    day: "2023-12-17",
  },
  {
    value: 60,
    day: "2021-02-21",
  },
  {
    value: 211,
    day: "2023-12-14",
  },
  {
    value: 226,
    day: "2023-04-27",
  },
  {
    value: 124,
    day: "2022-01-13",
  },
  {
    value: 87,
    day: "2023-04-24",
  },
  {
    value: 200,
    day: "2023-10-11",
  },
  {
    value: 179,
    day: "2018-06-16",
  },
  {
    value: 137,
    day: "2018-06-12",
  },
  {
    value: 90,
    day: "2022-10-15",
  },
  {
    value: 42,
    day: "2022-08-11",
  },
  {
    value: 234,
    day: "2022-08-15",
  },
  {
    value: 298,
    day: "2022-08-17",
  },
  {
    value: 231,
    day: "2021-04-30",
  },
  {
    value: 243,
    day: "2021-11-20",
  },
  {
    value: 128,
    day: "2022-07-08",
  },
  {
    value: 379,
    day: "2018-07-15",
  },
  {
    value: 266,
    day: "2021-07-23",
  },
  {
    value: 343,
    day: "2018-06-21",
  },
  {
    value: 90,
    day: "2021-03-27",
  },
  {
    value: 284,
    day: "2018-01-01",
  },
  {
    value: 294,
    day: "2018-01-08",
  },
  {
    value: 262,
    day: "2022-11-29",
  },
  {
    value: 159,
    day: "2021-05-26",
  },
  {
    value: 300,
    day: "2022-08-01",
  },
  {
    value: 253,
    day: "2022-03-05",
  },
  {
    value: 51,
    day: "2018-07-30",
  },
  {
    value: 397,
    day: "2022-03-06",
  },
  {
    value: 74,
    day: "2023-05-09",
  },
  {
    value: 370,
    day: "2021-07-18",
  },
  {
    value: 127,
    day: "2021-08-04",
  },
  {
    value: 133,
    day: "2022-09-05",
  },
  {
    value: 224,
    day: "2021-12-02",
  },
  {
    value: 26,
    day: "2018-06-23",
  },
  {
    value: 266,
    day: "2021-05-05",
  },
  {
    value: 380,
    day: "2018-03-04",
  },
  {
    value: 235,
    day: "2018-07-02",
  },
  {
    value: 175,
    day: "2021-09-16",
  },
  {
    value: 212,
    day: "2018-05-21",
  },
  {
    value: 55,
    day: "2022-08-03",
  },
  {
    value: 42,
    day: "2021-01-06",
  },
  {
    value: 356,
    day: "2021-11-27",
  },
  {
    value: 36,
    day: "2021-07-15",
  },
  {
    value: 330,
    day: "2021-05-27",
  },
  {
    value: 309,
    day: "2022-06-21",
  },
  {
    value: 301,
    day: "2021-10-01",
  },
  {
    value: 385,
    day: "2021-01-22",
  },
  {
    value: 175,
    day: "2018-03-24",
  },
  {
    value: 330,
    day: "2022-10-01",
  },
  {
    value: 303,
    day: "2021-04-08",
  },
  {
    value: 76,
    day: "2018-01-05",
  },
  {
    value: 249,
    day: "2021-07-30",
  },
  {
    value: 328,
    day: "2022-07-25",
  },
  {
    value: 45,
    day: "2023-09-04",
  },
  {
    value: 329,
    day: "2023-12-25",
  },
  {
    value: 231,
    day: "2018-03-08",
  },
  {
    value: 190,
    day: "2023-09-26",
  },
  {
    value: 323,
    day: "2021-03-30",
  },
  {
    value: 332,
    day: "2021-10-10",
  },
  {
    value: 308,
    day: "2023-05-24",
  },
  {
    value: 36,
    day: "2021-07-06",
  },
  {
    value: 312,
    day: "2018-07-11",
  },
  {
    value: 369,
    day: "2021-09-10",
  },
  {
    value: 338,
    day: "2023-08-07",
  },
  {
    value: 168,
    day: "2021-09-21",
  },
  {
    value: 175,
    day: "2021-01-21",
  },
  {
    value: 324,
    day: "2022-04-24",
  },
  {
    value: 326,
    day: "2018-03-18",
  },
  {
    value: 16,
    day: "2022-01-05",
  },
  {
    value: 365,
    day: "2018-05-14",
  },
  {
    value: 168,
    day: "2021-02-14",
  },
  {
    value: 241,
    day: "2021-12-13",
  },
  {
    value: 111,
    day: "2023-11-06",
  },
  {
    value: 219,
    day: "2023-04-06",
  },
  {
    value: 210,
    day: "2022-07-04",
  },
  {
    value: 143,
    day: "2018-05-01",
  },
  {
    value: 10,
    day: "2023-04-15",
  },
  {
    value: 90,
    day: "2022-04-05",
  },
  {
    value: 141,
    day: "2023-05-27",
  },
  {
    value: 10,
    day: "2018-05-29",
  },
  {
    value: 204,
    day: "2022-12-04",
  },
  {
    value: 42,
    day: "2021-04-22",
  },
  {
    value: 336,
    day: "2022-08-04",
  },
  {
    value: 139,
    day: "2022-10-27",
  },
  {
    value: 28,
    day: "2018-02-23",
  },
  {
    value: 183,
    day: "2021-04-27",
  },
  {
    value: 25,
    day: "2022-04-07",
  },
  {
    value: 60,
    day: "2021-05-01",
  },
  {
    value: 221,
    day: "2021-05-10",
  },
  {
    value: 235,
    day: "2021-09-11",
  },
  {
    value: 220,
    day: "2022-07-02",
  },
  {
    value: 186,
    day: "2018-04-01",
  },
  {
    value: 394,
    day: "2021-04-09",
  },
  {
    value: 385,
    day: "2022-05-05",
  },
  {
    value: 10,
    day: "2018-03-23",
  },
  {
    value: 252,
    day: "2021-11-12",
  },
  {
    value: 254,
    day: "2022-07-17",
  },
  {
    value: 233,
    day: "2022-07-28",
  },
  {
    value: 19,
    day: "2022-08-29",
  },
  {
    value: 300,
    day: "2021-04-10",
  },
  {
    value: 151,
    day: "2018-06-09",
  },
  {
    value: 398,
    day: "2022-07-22",
  },
  {
    value: 143,
    day: "2018-04-13",
  },
  {
    value: 376,
    day: "2021-11-30",
  },
  {
    value: 118,
    day: "2022-12-14",
  },
  {
    value: 58,
    day: "2018-01-29",
  },
  {
    value: 302,
    day: "2022-06-03",
  },
  {
    value: 305,
    day: "2021-04-12",
  },
  {
    value: 49,
    day: "2018-03-27",
  },
  {
    value: 229,
    day: "2022-12-17",
  },
  {
    value: 237,
    day: "2023-05-03",
  },
  {
    value: 62,
    day: "2021-03-03",
  },
  {
    value: 370,
    day: "2023-09-19",
  },
  {
    value: 360,
    day: "2021-12-29",
  },
  {
    value: 109,
    day: "2021-01-26",
  },
  {
    value: 185,
    day: "2018-07-29",
  },
  {
    value: 359,
    day: "2018-01-04",
  },
  {
    value: 207,
    day: "2021-07-24",
  },
  {
    value: 369,
    day: "2023-12-20",
  },
];

export const funnelData = [
  {
    id: "> 200k",
    value: 12,
    label: "> 200k",
  },
  {
    id: "100k to 200k",
    value: 25,
    label: "100k to 200k",
  },
  {
    id: "65k to 100k",
    value: 92,
    label: "65k to 100k",
  },
  {
    id: "35k to 65k",
    value: 85,
    label: "35k to 65k",
  },

  {
    id: "20k to 35k",
    value: 54,
    label: "20k to 35k",
  },

  {
    id: "< 20k",
    value: 13,
    label: "< 20k",
  },
];
