import React from "react";
import { Menu, ActionIcon, Text } from "@mantine/core";
import { IconDotsVertical } from "@tabler/icons-react";
import _ from "lodash";

const GroupMenu = ({ menu }) => {
  return (
    <Menu shadow="md" width={150}>
      <Menu.Target>
        <ActionIcon size={15} variant="subtle" color="gray" aria-label="Menu">
          <IconDotsVertical size="xs" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {menu.map((item) => (
          <Menu.Item color={item.color} onClick={item.onClick}>
            <Text size="xs">{item.label}</Text>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default GroupMenu;
