import React, { useEffect, useRef, useState } from "react";

import {
  Group,
  Paper,
  Select,
  SimpleGrid,
  Title,
  Text,
  Table,
  Tabs,
  Divider,
} from "@mantine/core";

import moment from "moment";

import _ from "lodash";
import { useServerApi } from "../hooks/useServerApi";
import DataTable from "./dataTable";
import FormSection from "./formSection";
import { useCellRender } from "../hooks/useCellRender";

const CPDStatCard = ({
  label,
  dueDate,
  startDate,
  credit,
  creditNeed,
  licence,
  ...props
}) => {
  return (
    <Paper radius="md" p="md" key={label} {...props} shadow="md">
      <Group justify="space-between" mb="xl">
        <Title order={6}> {label} </Title>
        <Text size={"xs"} color="dimmed">
          {licence}
        </Text>
      </Group>
      <Group justify="space-between" mb="xl">
        <span>
          <Group align={"baseline"}>
            <Title color="cyan">{credit}</Title>
            <Title color={"dimmed"} size="xs" weight={1}>
              / {creditNeed}
            </Title>
          </Group>

          <Text size="xs" color="dimmed">
            Earned
          </Text>
        </span>
        <span>
          <Group align={"baseline"}>
            <Title color={"red"}>{Math.max(creditNeed - credit, 0)}</Title>
            <Title color={"dimmed"} size="xs" weight={1}>
              / {creditNeed}
            </Title>
          </Group>
          <Text size="xs" color="dimmed">
            OutStanding
          </Text>
        </span>
      </Group>
    </Paper>
  );
};

const CPDMPFStats = ({ cpdData }) => {
  return (
    cpdData && (
      <SimpleGrid cols={4} breakpoints={[{ maxWidth: "sm", cols: 1 }]} mt="xl">
        <CPDStatCard
          label="MPF (Non-Core)"
          dueDate={cpdData.MPFDueDate}
          startDate={cpdData.MPFStartDate}
          creditNeed={cpdData.mpf_noncore_need}
          credit={cpdData.MPFNonCoreCredit}
          licence={cpdData.mpfaNumber}
        ></CPDStatCard>
        <CPDStatCard
          label="MPF (Core)"
          dueDate={cpdData.MPFDueDate}
          startDate={cpdData.MPFStartDate}
          creditNeed={cpdData.mpf_core_need}
          credit={cpdData.MPFCoreCredit}
          licence={cpdData.mpfaNumber}
        ></CPDStatCard>
      </SimpleGrid>
    )
  );
};
const CPDIAStats = ({ cpdData }) => {
  return (
    cpdData && (
      <SimpleGrid cols={4} breakpoints={[{ maxWidth: "sm", cols: 1 }]} mt="xl">
        <CPDStatCard
          label="IA"
          dueDate={cpdData.IADueDate}
          startDate={cpdData.IAStartDate}
          creditNeed={cpdData.ia_need}
          credit={cpdData.IACredit}
          licence={cpdData.iaNumber}
        />

        <CPDStatCard
          label="IAER"
          dueDate={cpdData.IADueDate}
          startDate={cpdData.IAStartDate}
          creditNeed={cpdData.iaer_need}
          credit={cpdData.IAERCredit}
          licence={cpdData.iaNumber}
        ></CPDStatCard>
      </SimpleGrid>
    )
  );
};

const CPDRecords = ({ records }) => {
  const [cellRender] = useCellRender();

  return (
    records && (
      <FormSection title={"CPD Courses"} mt="xl">
        <DataTable
          hideSearchBar={true}
          data={records}
          hidePagination={true}
          columns={[
            {
              field: "dateEnroll",
              sortable: true,
              headerName: "Course Date",
              cellRender: (col, data) => moment(data.dateEnroll).format("LL"),
            },

            {
              field: "courseTitle",
              headerName: "Course",
            },

            {
              field: "courseCode",
              headerName: "Course Code",
            },

            {
              field: "IACredit",
              headerName: "IA",
            },
            {
              field: "IAERCredit",
              headerName: "IAER",
            },

            {
              field: "MPFCoreCredit",
              headerName: "MPF (Core)",
            },

            {
              field: "MPFNonCoreCredit",
              headerName: "MPF (Non-Core)",
            },
          ]}
        />
      </FormSection>
    )
  );
};

const CPDIA = ({ user }) => {
  const [years, setYears] = useState([]);
  const [cpdData, setCpdData] = useState();
  const numYears = 3;
  const [api] = useServerApi();

  const currentYear = moment().year();
  const startYear = moment().isBefore(`${currentYear}-08-01`)
    ? currentYear
    : currentYear + 1;

  useEffect(() => {
    const yrs = [];
    for (let i = 0; i < numYears; i++) {
      let yr = startYear - i;
      yrs.push({
        label: `${yr - 1} - ${yr}`,
        value: yr,
      });
    }
    setYears(yrs);
    fetchCPD(startYear);
  }, []);

  useEffect(() => {
    fetchCPD(startYear);
  }, [user._id]);

  const fetchCPD = async (year) => {
    try {
      let result = await api.Cpd.getUserDetail({
        year,
        userId: user._id,
        type: "IA",
      });
      if (!result || !result.data) return;
      setCpdData(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Group>
        <Select
          data={years}
          with={10}
          // label="Year"
          defaultValue={startYear}
          onChange={(yr) => fetchCPD(yr)}
        />
      </Group>
      <CPDIAStats cpdData={cpdData}></CPDIAStats>
      <CPDRecords records={cpdData?.cpdRecords} />
      <Group>
        <Text size={"xs"} color="dimmed">
          Period : {cpdData?.IAStartDate} to {cpdData?.IADueDate}
        </Text>
      </Group>
    </>
  );
};
const CPDMPF = ({ user }) => {
  const [years, setYears] = useState([]);
  const [cpdData, setCpdData] = useState();
  const numYears = 3;
  const [api] = useServerApi();
  const startYear = moment().year();

  useEffect(() => {
    const yrs = [];

    for (let i = 0; i < numYears; i++) {
      let yr = startYear - i;
      yrs.push({
        label: yr,
        value: yr,
      });
    }
    setYears(yrs);
  }, []);

  useEffect(() => {
    fetchCPD(startYear);
  }, [user._id]);

  const fetchCPD = async (year) => {
    try {
      let result = await api.Cpd.getUserDetail({
        year,
        userId: user._id,
        type: "MPF",
      });
      if (!result || !result.data) return;
      setCpdData(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Group>
        <Select
          data={years}
          with={10}
          // label="Year"
          defaultValue={moment().year()}
          onChange={fetchCPD}
        />
      </Group>
      <CPDMPFStats cpdData={cpdData}></CPDMPFStats>
      <CPDRecords records={cpdData?.cpdRecords} />
      <Group>
        <Text size={"xs"} color="dimmed">
          Period : {cpdData?.MPFStartDate} to {cpdData?.MPFDueDate}
        </Text>
      </Group>
    </>
  );
};
const UserCPDDetail = ({ form: mainForm, mainUser }) => {
  const user = mainUser ? mainUser : mainForm.values;
  return (
    <>
      {/* <ReactJson style={{ background: "white" }} collapsed src={user} /> */}
      {/* <ReactJson style={{ background: "white" }} collapsed src={user} /> */}
      <Tabs defaultValue="IA" variant="outline">
        <Tabs.List>
          <Tabs.Tab value="IA">IA</Tabs.Tab>
          <Tabs.Tab value="MPF">MPF</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="IA" pt="xl">
          <CPDIA user={user} />
        </Tabs.Panel>

        <Tabs.Panel value="MPF" pt="xl">
          <CPDMPF user={user} />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default UserCPDDetail;
