import _ from "lodash";
import { Text, Title, Divider, Group, ThemeIcon, Badge } from "@mantine/core";
import React from "react";
import moment from "moment";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";

import { IconClick, IconCash, IconHeart } from "@tabler/icons-react";
import { useFormatter } from "../hooks/useFomatter";
import CalendarChart from "./chartWidget/CalendarChart";

export default function ItemBasicInfo({ form }) {
  const [cellRender] = useCellRender();
  const [formatter] = useFormatter();
  const item = form.values;
  const dataFontWeight = 600;

  const emptyStr = "-";

  const fields = [
    { title: "Total inventory", value: item.totalInventory ?? 0 },
    {
      title: "Average Utilization",
      value: formatter.percentage(item.utilization ?? 0),
    },
    {
      title: "Average Unit Cost",
      value: formatter.currency(item.averageUnitCost ?? 0),
    },
  ];
  return item && <InfoGridList fields={fields} values={item} />;
}
