import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  TextInput,
  Grid,
  Table,
  Text,
  ActionIcon,
  Group,
  Textarea,
} from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import {
  IconCirclePlus,
  IconExchange,
  IconMultiplier1x,
  IconTrash,
} from "@tabler/icons-react";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";

import ArrayTable from "./arrayTable";
import JsonViewer from "./common/jsonViewer";
import TemplateSaveLoadButtons from "./templateSaveLoadButtons";

const Terms = ({ form, name, templateCategory = "terms" }) => {
  const terms = _.get(form.values, "terms");
  const rowInitValue = {
    title: "",
    detail: "",
  };

  const fields = [
    {
      header: "Term Detail",
      headerWidth: "100%",
      fieldName: "detail",
      component: Textarea,
      props: {
        minRows: 1,
        autosize: true,
        py: 0,
      },
    },
  ];

  const loadFromTemplate = (template) => {
    console.log("loadFromTemplate", template);
    if (!template.content) return;
    form.setFieldValue(name, template.content);
  };

  return (
    <>
      <Group justify="right" mb="xl" gap={5}>
        <TemplateSaveLoadButtons
          templateCategory={templateCategory}
          onLoadTemplate={loadFromTemplate}
          currentContent={terms}
        />
      </Group>

      <ArrayTable
        form={form}
        name={name}
        rowInitValue={rowInitValue}
        fields={fields}
      />
    </>
  );
};

export default Terms;
